<template>
    <a-modal v-model:visible="visibleFlag" width="600px" title="空调控制设定" @cancel="handleClose">
        <div>
            <div class="models">
                <a-radio-group v-model:value="currentModel" button-style="solid">
                    <a-radio-button value="1">手动模式</a-radio-button>
                    <a-radio-button value="2">夏季模式</a-radio-button>
                    <!-- <a-radio-button value="winter">冬季模式</a-radio-button> -->
                </a-radio-group>
            </div>
            <div v-if="currentModel == 1">
                <a-form :model="formState" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
                    autocomplete="off">
                    <a-form-item label="开关机" name="username">
                        <a-switch v-model:checked="controlForm.RST" />
                    </a-form-item>
                    <a-form-item label="模式" name="username">
                        <a-radio-group v-model:value="controlForm.ktMode">
                            <a-radio-button value="auto">自动</a-radio-button>
                            <a-radio-button value="cooling">制冷</a-radio-button>
                            <a-radio-button value="arefaction">除湿</a-radio-button>
                            <a-radio-button value="ventilation">通风</a-radio-button>
                            <a-radio-button value="heating">制热</a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="温度" name="username">
                        <a-input v-model:value="controlForm.setTemp" style="width: 280px;">
                            <template #suffix>
                                ℃
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item label="风速" name="username">
                        <a-radio-group v-model:value="controlForm.windMode">
                            <a-radio-button value="auto">自动</a-radio-button>
                            <a-radio-button value="silent">静音</a-radio-button>
                            <a-radio-button value="midSpeed">中风速</a-radio-button>
                            <a-radio-button value="highSpeed">高风速</a-radio-button>
                        </a-radio-group>
                    </a-form-item>

                </a-form>
            </div>
            <div v-if="currentModel == 2 && roomType == 1" class="block">
                <section>
                    <label>入住15分钟空调设定（如果已开启）</label>
                    <span>26℃</span>
                </section>
                <section>
                    <label>晚上1点之后空调设定（如果已开启）</label>
                    <span>26℃</span>
                </section>
            </div>
            <div v-if="currentModel == 2 && roomType == 2" class="block block_style">
                <section>
                    <label>7:00 ~ 13:00</label>
                    <span>25℃</span>
                </section>
                <section>
                    <label>13:00 ~ 17:00</label>
                    <span>27℃</span>
                </section>
                <section>
                    <label>17:00 ~ 23:00</label>
                    <span>25℃</span>
                </section>
                <section>
                    <label>23:00 ~ 次日7:00</label>
                    <span>关闭</span>
                </section>
            </div>
            <div v-if="currentModel == 2 && roomType == 3" class="block block_style">
                <section>
                    <label>17:00 ~ 21:00</label>
                    <span>26℃</span>
                </section>
                <section>
                    <label>其余时段</label>
                    <span>关闭</span>
                </section>
            </div>
        </div>
        <template #footer>
            <a-button key="back" @click="handleClose">取消</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">启用</a-button>
        </template>
    </a-modal>
</template>
<script setup>
import { defineProps, defineEmits, computed, reactive, ref, onMounted } from 'vue'
import { apiDeviceCmdWrite } from '@/api/centerMonitor/projectMonitor.js'
import { message } from 'ant-design-vue';

const props = defineProps({
    visible: {
        type: Boolean,
        default: false
    },
    roomType: {
        type: String
    },
    info: {
        type: Object,
        default() {
            return {}
        }
    },
    deviceInfo: {
        type: Object,
        default() {
            return {}
        }
    }
})
const emits = defineEmits(['close', 'ok'])

const currentModel = ref('1') //当前模式
const controlForm = reactive({
    mode: 'manualMode',
    RST: false,
    ktMode: 'auto',
    setTemp: '25',
    windMode: 'auto'
})

const visibleFlag = computed(() => {
    return props.visible
})

const handleClose = () => {
    emits('close')
}

const handleOk = () => {
    console.log(123, props.info)
    let param = {
        mode: currentModel.value,
        bizDeviceId: props.info && props.info.devices[0],
        detail: [
            {
                attrCode: 'mode',
                value: controlForm.mode
            },
            {
                attrCode: 'RST',
                value: controlForm.RST ? 'on' : 'off'
            },
            {
                attrCode: 'ktMode',
                value: controlForm.ktMode
            },
            {
                attrCode: 'setTemp',
                value: controlForm.setTemp
            },
            {
                attrCode: 'windMode',
                value: controlForm.windMode
            }
        ]
    }
    apiDeviceCmdWrite(param).then(() => {
        message.success('操作成功！')
        emits('ok')
    })

}
onMounted(() => {
    currentModel.value = props.deviceInfo && props.deviceInfo.mod
})

</script>
<style lang='less' scoped>
.models {
    display: flex;
    margin-bottom: 25px;

    span {
        width: 88px;
        height: 32px;
        border-radius: 2px;
        align-items: center;
        padding: 5px 16px;

        &.active {
            background: #0256FF;
            color: #fff;
        }
    }
}

.block {
    padding-left: 20px;

    section {
        display: flex;

        label {
            width: 260px;
        }

        margin-bottom: 10px;
    }
}

.block_style {
    label {
        width: 200px !important;
    }
}
</style>
