<template>
    <div class="templte-wrap">
        <div class="rooms">
            <div class="roomWrap" :style="roomWrapStyle">
                <span :class="{ active: item.regionName == currentRoom }" @click="handleChooseRoom(item)"
                    v-for="item in rooms" :key="item.regionName">{{ item.regionName }}</span>
            </div>
        </div>
        <div class="content">
            <div class="controls">
                <h1 :class="{ 'ok': deviceStatusInfo.comStatus == 1 }">通讯状态</h1>
                <div class="infos">
                    <section>
                        <span>控制模式</span>
                        <div>
                            <!-- <a-select ref="select" v-model:value="value1" style="width: 170px" placeholder="请选择项目">
                                <a-select-option value="jack">xxxx</a-select-option>
                            </a-select> -->
                            <a-input style="width: 170px" :value="deviceStatusInfo.modelDes" />
                            <a-button @click="handleSetModel">设定</a-button>
                        </div>
                    </section>
                    <section>
                        <span>运行状态</span>
                        <div>
                            <a-input style="width: 248px" :value="deviceStatusInfo.runStatus == 1 ? '运行' : '关闭'" />
                        </div>
                    </section>
                    <section>
                        <span>空调设定</span>
                        <div>
                            <a-input :value="deviceStatusInfo.tmpSet" style="width: 248px;">
                                <template #suffix>
                                    ℃
                                </template>
                            </a-input>
                        </div>
                    </section>
                    <section>
                        <span>当前温度</span>
                        <div>
                            <a-input :value="deviceStatusInfo.tempNow" style="width: 248px;">
                                <template #suffix>
                                    ℃
                                </template>
                            </a-input>
                        </div>
                    </section>
                    <section>
                        <span>当前功率</span>
                        <div>
                            <a-input :value="deviceStatusInfo.power" style="width: 248px;">
                                <template #suffix>
                                    kW
                                </template>
                            </a-input>
                        </div>
                    </section>
                    <section>
                        <span>当日电量</span>
                        <div>
                            <a-input :value="deviceStatusInfo.electricityQuantity" style="width: 248px;">
                                <template #suffix>
                                    kWh
                                </template>
                            </a-input>
                        </div>
                    </section>
                </div>
            </div>
            <div class="charts" v-if="isRoom">
                <div class="dayData">
                    <h2>当日实时数据</h2>
                    <div style="width: 98%;height: 90%;" ref="dayDataRef" key="1"></div>
                </div>
                <div class="monthData">
                    <h2>最近1月日统计</h2>
                    <div style="width: 98%;height: 90%;" ref="monthDataRef"></div>
                </div>
            </div>
            <div class="charts" v-else>
                <div class="dayDataFull">
                    <h2>当日实时数据</h2>
                    <div style="width: 98%;height: 90%;" ref="dayDataRef" key="2"></div>
                </div>
            </div>
        </div>
    </div>
    <control-modal :info="currentRoomInfo" :deviceInfo="deviceStatusInfo" :roomType="roomType" v-if="modalVisible"
        :visible="modalVisible" @close="modalVisible = false" @ok="handleModalOk" />
</template>
<script setup>
import { ref, computed, getCurrentInstance, onMounted, markRaw, reactive, onBeforeUnmount, nextTick } from 'vue'
import controlModal from '../modal/intelligentControlModal'
import {
    apiJinJiangReginList,
    apiJinJiangDeviceStatus,
    apiJinJiangDeviceMonthStatus,
    apiJinJiangDeviceTodayStatus,
    apiRoomType
} from '@/api/centerMonitor/projectMonitor.js'
const parentInfo = ref({})

const currentRoom = ref('')
const currentRoomInfo = ref({})
const rooms = ref([])
const isRoom = ref(true)
const roomWrapStyle = computed(() => {
    let len = rooms.value.length || 12;
    return {
        width: (len * 64 + len * 12) + 'px'
    }
})

const initRoomInfo = async () => {

    let res = await apiJinJiangReginList({ bizProjectId: parentInfo.value.projectId.join(',') })
    rooms.value = res.result
    currentRoomInfo.value = res.result[0]
    currentRoom.value = res.result[0] && res.result[0].regionName
    isRoom.value = res.result[0] && res.result[0].regionType == 1
    initDeviceInfo()
}

const deviceStatusInfo = ref({});
const initDeviceInfo = () => {
    let param = {
        bizProjectId: parentInfo.value.projectId.join(','),
        regionName: currentRoom.value
    }
    apiJinJiangDeviceStatus(param).then(res => {
        res.result.comStatus = parseInt(res.result.comStatus)
        res.result.runStatus = parseInt(res.result.runStatus)
        let modelDes;
        if (res.result.mod == 1) {
            modelDes = '手动模式'
        } else if (res.result.mod == 2) {
            modelDes = '夏季模式'
        }
        res.result.modelDes = modelDes
        deviceStatusInfo.value = res.result || {}
    })
    apiJinJiangDeviceMonthStatus(param).then(res => {
        let info = res.result || []
        let maxLeft
        let maxRight
        info.forEach(item => {
            if (item.desc === '电量') {
                maxLeft = Math.max.apply(null, item.ylist)
            }
            if (item.desc === '入住时长') {
                maxRight = Math.max.apply(null, item.ylist)
            }
        })
        let cha = Math.max(maxLeft, maxRight)
        if ((cha % 10) != 0) {
            cha = cha + (10 - (cha % 10));
        }
        chartOptions.monthDataChartOption.yAxis[0].max = cha
        chartOptions.monthDataChartOption.yAxis[1].max = cha
        chartOptions.monthDataChartOption.legend.data = info.map(item => item.desc)
        chartOptions.monthDataChartOption.xAxis[0].data = info[0] && info[0].xlist
        chartOptions.monthDataChartOption.series = info.map(item => {

            return {
                name: item.desc,
                type: item.desc === '电量' ? 'bar' : 'line',
                barWidth: 16,
                barGap: 0,
                yAxisIndex: item.desc === '电量' ? 0 : 1,
                data: item.ylist,
                tooltip: {
                    valueFormatter: function (value) {
                        let unit = item.desc === '电量' ? 'kWh' : 'h'
                        return value ? (value + unit) : '-';
                    }
                },
            }
        })

        if (monthDataChart.value) {
            monthDataChart.value.clear()
            monthDataChart.value.setOption(chartOptions.monthDataChartOption);
        }

    })
    apiJinJiangDeviceTodayStatus(param).then(res => {
        dayDataChart.value && dayDataChart.value.clear()
        let info = res.result || []
        chartOptions.dayDataChartOption.legend.data = info.map(item => item.desc)
        chartOptions.dayDataChartOption.xAxis[0].data = info[0] && info[0].xlist
        chartOptions.dayDataChartOption.series = info.map(item => {
            return {
                name: item.desc,
                type: 'line',
                data: item.ylist,
                yAxisIndex: item.desc === '房间空调功率' ? 1 : 0,
            }
        })
        dayDataChart.value && dayDataChart.value.setOption(chartOptions.dayDataChartOption);
    })
}
const handleChooseRoom = (item) => {
    currentRoom.value = item.regionName;
    currentRoomInfo.value = item;
    isRoom.value = item.regionType == 1
    nextTick(() => {
        if (dayDataRef.value) {
            dayDataChart.value.clear()
            dayDataChart.value = markRaw(proxy.$echarts.init(dayDataRef.value));
            dayDataChart.value.setOption(chartOptions.dayDataChartOption);
        }
        if (monthDataRef.value) {
            monthDataChart.value.clear()
            monthDataChart.value = markRaw(proxy.$echarts.init(monthDataRef.value));
            monthDataChart.value.setOption(chartOptions.monthDataChartOption);
        }
    })
    initDeviceInfo()
}

const modalVisible = ref(false)
const handleModalOk = () => {
    modalVisible.value = false
}


const { proxy } = getCurrentInstance();
const dayDataRef = ref()
const dayDataChart = ref()

const monthDataRef = ref()
const monthDataChart = ref()

const chartOptions = reactive({
    dayDataChartOption: {
        tooltip: {
            trigger: 'axis',
            formatter: function (params) {

                var relVal = params[0].name + '时'
                for (var i = 0; i < params.length; i++) {
                    let unit = params[i].seriesName.includes('温度') ? '℃':'kW'
                    relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + `${params[i].value ? (params[i].value + unit) : '-'}`
                }
                // relVal += '<br/>' + `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#00CBFF;"></span>` + params[0].seriesName + ' : ' + params[0].value + '%'
                return relVal
            }
        },
        legend: {
            top: 0,
            data: [],
            itemWidth: 16,
            itemHeight: 8,
            textStyle: {
                fontSize: 10,
            }
        },
        color: ['#548CFF', '#00B578', '#F478BC', '#FFC300'],
        grid: {
            top: '14%',
            left: '3%',
            right: '2%',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: [],
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    textStyle: {
                        fontSize: 10,
                        color: '#8F95B2'
                    },
                    formatter: '{value}时'
                },
                axisLine: {
                    lineStyle: {
                        color: '#D8DAE5'
                    }
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '单位：℃',
                min: 0,
                max: 50,
                interval: 5,
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                }
            },
            {
                type: 'value',
                name: '单位：kW',
                min: 0,
                max: 10,
                interval: 1,
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                }
            }
        ],
        series: []
    },
    monthDataChartOption: {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            top: 0,
            data: [],
            itemWidth: 16,
            itemHeight: 8,
            textStyle: {
                fontSize: 10,
            }
        },
        color: ['#FFC300', '#548CFF'],
        grid: {
            top: '14%',
            left: '4%',
            right: '3.5%',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: [],
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    textStyle: {
                        fontSize: 10,
                        color: '#8F95B2'
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: '#D8DAE5'
                    }
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '单位：kWh',
                min: 0,
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                }
            },
            {
                type: 'value',
                name: '单位：h',
                min: 0,
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                }
            },
        ],
        series: []
    }
})


const resizeChart = () => {
    dayDataChart.value.resize();
    monthDataChart.value && monthDataChart.value.resize();
};

const onResize = () => {
    window.addEventListener("resize", resizeChart);
};


const handleSetModel = () => {
    modalVisible.value = true
    getRoomType()
}

const roomType = ref('')
const getRoomType = () => {
    apiRoomType({
        bizProjectId: parentInfo.value.projectId.join(','),
        regionName: currentRoom.value
    }).then(res => {
        roomType.value = res.result
    })
}

onBeforeUnmount(() => {
    window.removeEventListener("resize", resizeChart);
});


onMounted(() => {
    nextTick(() => {
        if (dayDataRef.value) {
            dayDataChart.value = markRaw(proxy.$echarts.init(dayDataRef.value));
            dayDataChart.value.setOption(chartOptions.dayDataChartOption);
        }
        if (monthDataRef.value) {
            monthDataChart.value = markRaw(proxy.$echarts.init(monthDataRef.value));
            monthDataChart.value.setOption(chartOptions.monthDataChartOption);
        }
    })

    setTimeout(() => {
        onResize()
    }, 100)

    window.onmessage = function (event) {
        console.log(123456, event)
        if (typeof event.data == 'object') return;
        let info = JSON.parse(event.data)

        if (info.isParentData) {
            parentInfo.value = info.info
            initRoomInfo()
        }
    }
})


</script>
<style lang='less' scoped>
.rooms {
    width: 100%;
    height: 44px;
    overflow-x: auto;
    overflow-y: hidden;
}

.roomWrap {
    display: flex;
    flex-wrap: wrap;

    span {
        width: 64px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 4px;
        border-radius: 2px;
        background: #D8DAE5;
        margin-right: 12px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &.active {
            color: #fff;
            background: #0256FF;
        }
    }
}

.content {
    margin-top: 16px;
    display: flex;

    .controls {
        width: 288px;
        margin-right: 16px;
        height: calc(100vh - 65px);
        padding: 20px;
        border: 1px solid rgba(99, 116, 140, 0.2);

        h1 {
            position: relative;
            display: inline-block;
            font-size: 16px;
            line-height: 16px;
            font-weight: normal;
            margin-bottom: 20px;

            &::after {
                position: absolute;
                content: '';
                right: -24px;
                top: 3px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: grey;
            }

            &.ok {
                &::after {
                    background: green;
                }
            }
        }

        .infos {
            section {
                margin-bottom: 18px;

                &>span {
                    display: inline-block;
                    margin-bottom: 6px;
                }
            }
        }
    }

    .charts {
        width: calc(100vw - 304px);

        h2 {
            margin: 20px 0 0 20px;
            font-size: 16px;
            line-height: 16px;
            font-weight: normal;
        }

        .dayData {
            height: calc((100vh - 78px)/2);
            border: 1px solid rgba(99, 116, 140, 0.2);
            margin-bottom: 16px;
        }

        .dayDataFull {
            height: calc(100vh - 65px);
            border: 1px solid rgba(99, 116, 140, 0.2);
        }

        .monthData {
            height: calc((100vh - 84px)/2);
            border: 1px solid rgba(99, 116, 140, 0.2);
        }
    }
}

// :deep .ant-modal-mask {
//     background-color: none !important;
// }
</style>